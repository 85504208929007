// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'staging',
  firebase: {
    apiKey: 'AIzaSyDQ91YqXPmjbH4g-Ei5CMJvjWwqhSmEeSM',
    authDomain: 'botmind-io.firebaseapp.com',
    databaseURL: 'https://botmind-io.firebaseio.com',
    projectId: 'botmind-io',
    storageBucket: 'botmind-io.appspot.com',
    messagingSenderId: '877271272845',
    appId: '1:877271272845:web:fa18ab0bac46ae96d981c4',
    measurementId: 'G-8W88FGHGJN',
  },
  backendUrl: 'https://api.staging.botmind.ai',

  /*
   * Set log level for the whole app.
   *
   * Available levels are (in order):
   *  1. "silent" = no console messages at all
   *  2. "error"
   *  3. "warn"
   *  4. "info"
   *  5. "debug" = all messages
   */
  logLevel: 'debug',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
