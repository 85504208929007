import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Message } from '../model/message';

@Injectable({
  providedIn: 'root',
})
export class PopTriggerService {
  public messages: Message[] = [];
  public messageAdded: Subject<Message | null> = new Subject();

  private sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public addMessage(message: Message, fadeOut?: number) {
    const compareFn = (a: Message, b: Message) => {
      return a.sentDate - b.sentDate;
    };

    this.messages = this.messages.concat(message).sort(compareFn);

    this.sendRefreshIframeSignal();
    this.messageAdded.next(message);
    if (typeof fadeOut === 'number') {
      window.setTimeout(() => {
        this.removeMessage.bind(this)(message.uuid);
      }, fadeOut);
    }
  }

  public removeMessage(uuid: string) {
    const ind = this.messages.findIndex((m) => m.uuid === uuid);
    if (ind >= 0) {
      this.messages.splice(ind, 1);
      this.sendRefreshIframeSignal();
    }
  }

  public clearMessages() {
    if (this.messages.length > 0) {
      this.messages.splice(0, this.messages.length);
      this.sendRefreshIframeSignal();
    }
  }

  public sendRefreshIframeSignal() {
    void this.sleep(100).then(() => {
      this.messageAdded.next(null);
    });
  }
}
