import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { FormsModule } from '@angular/forms';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from '@solidexpert/ng-click-outside';
import {
  RemixIconModule,
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiAttachmentLine,
  RiChat3Line,
  RiCloseLine,
  RiHome2Line,
  RiMovieLine,
  RiSendPlane2Line,
  RiSubtractLine,
  RiThumbDownFill,
  RiThumbDownLine,
  RiThumbUpFill,
  RiThumbUpLine,
  RiWindow2Line,
} from 'angular-remix-icon';
import 'hammerjs';
import { FileUploadModule } from 'ng2-file-upload';
import { AutosizeModule } from 'ngx-autosize';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AppComponent } from './app/app.component';
import { httpLoaderFactory } from './app/app.module';
import { AddHeaderInterceptor } from './app/services/add-header-interceptor';
import { DisplayService } from './app/services/display.service';
import { LoggerService } from './app/services/logger/logger.service';
import { RedirectService } from './app/services/redirect.service';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      FormsModule,
      NgScrollbarModule,
      AutosizeModule,
      ClickOutsideModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
        defaultLanguage: 'en',
      }),
      FileUploadModule,
      FlexLayoutModule,
      NgxSkeletonLoaderModule,
      RemixIconModule.configure({
        RiChat3Line,
        RiWindow2Line,
        RiArrowLeftSLine,
        RiArrowRightSLine,
        RiThumbDownLine,
        RiThumbDownFill,
        RiThumbUpLine,
        RiThumbUpFill,
        RiCloseLine,
        RiHome2Line,
        RiSubtractLine,
        RiAttachmentLine,
        RiSendPlane2Line,
        RiMovieLine,
      }),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    DisplayService,
    RedirectService,
    DatePipe,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    LoggerService,
    {
      provide: APP_INITIALIZER,
      useFactory: (loggerService: LoggerService) => () => loggerService.setLogLevel(environment.logLevel),
      deps: [LoggerService],
      multi: true,
    },

    /*
     * Sentry logger
     * (must be removed from sources when unused because of its dependencies size)
     *
     * ADD:
     *  1. yarn add @sentry/angular @sentry/tracing @angular/router@^17.3.12
     *
     *  2. Add imports:
     *     import { Router } from '@angular/router';
     *     import { captureMessage, init, TraceService } from '@sentry/angular';
     *     import { SentryLoggerService } from './app/services/sentry-logger/sentry-logger.service';
     *
     *  3. Uncomment the following <Sentry>...</Sentry>
     *
     * REMOVE:
     *  1. yarn remove @sentry/angular @sentry/tracing @angular/router
     *
     *  2. Remove imports:
     *     import { Router } from '@angular/router';
     *     import { captureMessage, init, TraceService } from '@sentry/angular';
     *
     *  3. Comment the following <Sentry>...</Sentry>
     */
    // <Sentry>
    /*
    {
      provide: TraceService,
      deps: [Router],
      useFactory: () => () => {},
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (loggerService: SentryLoggerService) => () => {
        init({
          dsn: 'https://0c11678e108deb70bd812099603e7157@o299718.ingest.us.sentry.io/4508478770970624',
          integrations: [],
          sampleRate: 1 / 1000,
          environment: environment.name,
          enabled: true,
          debug: false,
        });
        loggerService.setCaptureMessage(captureMessage);
      },
      deps: [SentryLoggerService, TraceService],
      multi: true,
    },
    */
    // </Sentry>
  ],
}).catch((err) => {
  // eslint-disable-next-line no-console
  console.error(err);
});
